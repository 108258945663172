import React from 'react';
import DocumentsForm from '../DocumentsForm';
import PaymentForm from '../PaymentForm';

const Tabs = ({
  openTab,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  pagoObject,
}) => {
  return (
    <div
      className="px-2 py-5 flex-auto overflow-y-auto"
      style={{height: 'calc(100vh - 110px)'}}
    >
      <div className="tab-content tab-space">
        <div className={openTab === 1 ? 'block' : 'hidden'} id="tab1">
          <PaymentForm
            {...{values, handleChange, handleBlur, setFieldValue, pagoObject}}
          />
        </div>
        <div className={openTab === 2 ? 'block' : 'hidden'} id="tab2">
          <DocumentsForm
            {...{values, handleChange, handleBlur, setFieldValue, pagoObject}}
          />
        </div>
      </div>
    </div>
  );
};

export default Tabs;
