import React, {useEffect, useState} from 'react';
import {Formik, Field} from 'formik';
import {validate} from './validations';
import {TextFieldRender, SelectFieldRender} from 'src/components/FormRenders';
import Loading from 'src/components/Loading';
import logo from 'src/pages/images/logo.png';
import history from 'src/history';

const TicketForm = ({alert, client}) => {
  const [sucursalsOptions, setSucursalsOptions] = useState([]);
  useEffect(() => {
    async function fetchSucursal() {
      try {
        if (!sucursalsOptions.length) {
          const response = await client.get('/branchOffices', {
            'Content-Type': 'application/json',
          });
          const {data} = response;
          const sucursalsFetch = data.data || [];
          const sucursals = sucursalsFetch.map(sucursal => ({
            value: sucursal.id,
            label: sucursal.name,
          }));
          setSucursalsOptions(sucursals);
        }
      } catch (error) {
        alert.error(error.message);
      }
    }
    fetchSucursal();
  }, [alert, client, sucursalsOptions]);

  const formInitialValues = {
    folio: '',
    sucursal: '',
    total: 0,
  };

  const searchTicket = async (values, resetForm) => {
    try {
      const {folio, sucursal, total} = values;
      let ticket = folio;
      const response = await client.get(
        'ticket',
        {
          params: {
            ticket: folio,
            sucursal,
            total,
          },
        },
        {
          'Content-Type': 'application/json',
        },
      );
      const {data} = response;
      const {cfdi, download} = data.data;
      if (cfdi) {
        history.push({
          pathname: '/cdfi',
          state: {
            ticket,
          },
        });
      } else if (download) {
        history.push({
          pathname: `/download/${ticket}`,
          state: {},
        });
      } else {
        alert.success(data.message);
      }
      resetForm();
    } catch (error) {
      alert.error(error.message);
    }
  };
  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validate}
      onSubmit={async (values, {setSubmitting, resetForm}) => {
        setSubmitting(true);
        await searchTicket(values, resetForm);
        setSubmitting(false);
      }}
    >
      {({values, handleChange, handleSubmit, handleBlur, isSubmitting}) => {
        return (
          <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto h-auto w-40"
                src={logo}
                alt="Papelería Rosy"
              />
              <h2 className="mt-6 text-center text-2xl leading-9 font-extrabold text-gray-900 uppercase">
                Facturación
              </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field
                      label="Folio de ticket"
                      name="folio"
                      id="folio"
                      placeholder="Ingresar Folio"
                      component={TextFieldRender}
                      value={values.folio}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      classNameLabel="block ml-1 text-sm font-medium leading-5 text-gray-700 mb-2"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      otherProps={{
                        autoFocus: true,
                      }}
                    />
                  </div>

                  <div className="mt-6">
                    <Field
                      label="Sucursal"
                      name="sucursal"
                      id="sucursal"
                      placeholder="Selecciona la sucursal"
                      component={SelectFieldRender}
                      value={values.sucursal}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      classNameLabel="block ml-1 text-sm font-medium leading-5 text-gray-700 mb-2"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      otherProps={{ 
                        options: sucursalsOptions,
                      }}
                    />
                  </div>

                  <div className="mt-6">
                    <Field
                      label="Total del ticket"
                      name="total"
                      id="total"
                      placeholder="0.00"
                      component={TextFieldRender}
                      value={values.total}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      classNameLabel="block ml-1 text-sm font-medium leading-5 text-gray-700 mb-2"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      otherProps={{
                        autoFocus: true,
                      }}
                    />
                  </div>

                  <div className="mt-6">
                    <span className="block w-full rounded-md shadow-sm">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                      >
                        {!isSubmitting && 'Facturar'}
                        {isSubmitting && (
                          <Loading className="border-2 border-t-2 loader-div" />
                        )}
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default TicketForm;
