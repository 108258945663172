import * as Yup from 'yup';

export const validate = Yup.object().shape({
  rfc: Yup.string().required('Requerido'),
  nombre: Yup.string().required('Requerido'),
  calle: Yup.string(),
  numero: Yup.string(),
  numeroInterior: Yup.string(),
  colonia: Yup.string(),
  estado: Yup.string(),
  localidad: Yup.string(),
  municipio: Yup.string(),
  cp: Yup.string().required('Requerido'),
  email: Yup.string().required('Requerido').email('Email Invalido'),
  email2: Yup.string().email('Email Invalido'),
  email3: Yup.string().email('Email Invalido'),
  formaPago: Yup.string().required('Requerido'),
  confirmFormaPago: Yup.string()
    .required('Requerido')
    .oneOf([Yup.ref('formaPago'), null], 'Formas de pago son diferente'),
  metodoPago: Yup.string().required('Requerido'),
  usoCfdi: Yup.string().required('Requerido'),
  observaciones: Yup.string(),
});
