import * as Yup from 'yup';

export const validate = Yup.object().shape({
  paymentDate: Yup.string().required('Requerido')
  .test('Fecha verificada', 'Fecha Inválida', (val) => {
    let res = true;
    if(val) {
      const reExp = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
      res = reExp.test(val);
    }
    return res;
  }
  ),
  currency: Yup.string().required('Requerido'),
  paymentMethod: Yup.string().required('Requerido'),
  operation: Yup.string(),
  total: Yup.number()
    .typeError('Must be a number')
    .required('Requerido')
    .test('validTotañ', 'Monto debe ser mayor a 0', val => val > 0),
});
