import React, {useState, useEffect} from 'react';
import {Formik} from 'formik';
import {validate} from './validations';
import TabsMenu from './components/TabsMenu';
import Tabs from './components/Tabs';
import Loading from 'src/components/Loading';
import history from 'src/history';
import {paymentMethodsText} from './options';
import moment from 'moment';
import './style.css';
import _ from 'lodash';

const InvoiceComplementForm = ({location, client, alert}) => {
  const [openTab, setOpenTab] = useState(1);
  const [pagoObject, setPagoObject] = useState({});
  const [execute, setExecute] = useState(false);

  let ticket = '';
  if (location.state) {
    ticket = location.state.ticket ? location.state.ticket : ticket;
  }

  if (ticket === '') {
    history.push('/');
  }

  useEffect(() => {
    async function fetchTicket() {
      try {
        if (!execute) {
          const response = await client.get(`/invoicedTicket/${ticket}`, {
            'Content-Type': 'application/json',
          });
          const {data} = response;
          const pagoFetch = data.data || [];
          if (pagoFetch.length) {
            setPagoObject(pagoFetch[0]);
          }
          if (
            !pagoFetch[0].saldo_anterior ||
            pagoFetch[0].saldo_anterior === 0
          ) {
            history.push('/');
          }
          setExecute(true);
        }
      } catch (error) {
        setExecute(true);
        alert.error(error.message);
      }
    }
    fetchTicket();
  }, [alert, client, pagoObject, ticket, execute]);

  if (execute && _.isEmpty(pagoObject)) {
    history.push('/');
  }

  const formInitialValues = {
    paymentDate: moment().format('YYYY-MM-DD'),
    operation: '',
    paymentMethod: '01',
    currency: 'MXN Pesos Mexicanos',
    total: 0,
    serie: pagoObject.serie_dr || '',
    parcial: pagoObject.num_parcialidad || '',
    folio: pagoObject.folio_dr || '',
    oldTotal: pagoObject.saldo_anterior || 0,
    paymentMethodDocumemnt: pagoObject.metodo_pago || '',
    totalPayment: 0,
    difference: pagoObject.saldo_anterior || 0,
  };

  const handleTab = tab => {
    setOpenTab(tab);
  };

  const createComplement = async (values, resetForm) => {
    try {
      const formData = {
        formaPago: values.paymentMethod,
        nombreFormaPago: paymentMethodsText[values.paymentMethod],
        pagos: {
          id_ticket: pagoObject.id_ticket,
          monto: values.total,
          fechaPago: moment(values.paymentDate).format('YYYY-MM-DDTHH:mm:ss'),
          metodoDePagoDR: pagoObject.metodo_pago,
          serieDR: values.serie,
          folioDR: values.folio,
          idDocumento: pagoObject.id_documento,
          numOperacion: values.operation,
          numParcialidad: values.parcial,
          impSaldoAnt: values.oldTotal,
          impPagado: values.total,
          impSaldoInsoluto: values.difference,
        },
        receptor: {
          rfc: pagoObject.rfc_emisor,
          nombre: pagoObject.nombre_emisor,
          cp: pagoObject.cp_emisor,
          regimenFiscal: pagoObject.regimenFiscal_emisor,
          nombreRegimenFiscal: pagoObject.nombreRegimen_receptor,
          usoCFDI: 'CP01',
          nombreUsoCFDI: 'Por definir',
          emails: [pagoObject.email],
        },
      };

      const response = await client.post('/invoiceCRP', formData, {
        'Content-Type': 'application/json',
      });
      const {data} = response;
      alert.success(data.message);
      resetForm();
      history.push(`/pagos/${ticket}`);
    } catch (error) {
      alert.error(error.message);
      console.error(error);
    }
  };

  if (!_.isEmpty(pagoObject)) {
    return (
      <>
        <div className="flex flex-wrap bg-white max-w-7xl mx-auto border border-gray-300">
          <div className="w-full">
            <TabsMenu handleTab={handleTab} openTab={openTab} />
            <Formik
              initialValues={formInitialValues}
              validationSchema={validate}
              onSubmit={async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                await createComplement(values, resetForm);
                setSubmitting(false);
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
                isSubmitting,
                errors,
              }) => {
                if (!_.isEmpty(errors)) {
                  console.log(errors);
                }
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                      <Tabs
                        openTab={openTab}
                        {...{
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                          pagoObject,
                        }}
                      />
                      <div className="px-4 py-3 bg-gray-200 text-right sm:px-6">
                        {/* <span class="inline-flex rounded-md shadow-sm mx-2">
                        <button
                          type="button"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out"
                        >
                          Vaciar
                        </button>
                      </span> */}
                        <span className="inline-flex rounded-md shadow-sm">
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out"
                          >
                            {!isSubmitting && 'Timbrar'}
                            {isSubmitting && (
                              <Loading className="border-2 border-t-2 loader-div" />
                            )}
                          </button>
                        </span>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="div-loading">
        <Loading className="border-8 border-t-8 h-64 w-64" />
      </div>
    );
  }
};

export default InvoiceComplementForm;
