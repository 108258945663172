import * as Yup from 'yup';

export const validate = Yup.object().shape({
  folio: Yup.string().required('Requerido'),
  sucursal: Yup.string().required('Requerido'),
  total: Yup.number()
    .typeError('Must be a number')
    .required('Requerido')
    .test('validTotañ', 'Requerido', val => val > 0),
});
