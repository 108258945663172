import React from 'react';
import {Formik} from 'formik';
import {validate} from './validations';
import Loading from 'src/components/Loading';
import {paymentMethodsText, usoCdifText, paymentFormsText} from './options';
import history from 'src/history';
import {Fields} from './formFields';

const CdfiForm = ({client, alert, location}) => {
  let ticket = '';
  if (location.state) {
    ticket = location.state.ticket ? location.state.ticket : ticket;
  }

  if (ticket === '') {
    history.push('/');
  }

  const formInitialValues = {
    rfc: '',
    nombre: '',
    calle: '',
    numero: '',
    numeroInterior: '',
    colonia: '',
    estado: '',
    localidad: '',
    municipio: '',
    cp: '',
    email: '',
    email2: '',
    email3: '',
    regime: '',
    formaPago: '',
    confirmFormaPago: '',
    metodoPago: '',
    usoCfdi: '',
    observaciones: '',
    regimeOptions: '',
  };

  const createCdfi = async (values, resetForm) => {
    try {
      let emails = [values.email];
      if (values.email2 !== '') {
        emails = [...emails, values.email2];
      }
      if (values.email3 !== '') {
        emails = [...emails, values.email3];
      }

      const regimeOption = values.regimeOptions.find(
        regime => regime.value === values.regime,
      );

      const formData = {
        ticket,
        formaPago: values.formaPago,
        nombreFormaPago: paymentMethodsText[values.formaPago],
        tipoComprobante: 'I',
        nombreTipoComprobante: 'Ingreso',
        metodoPago: values.metodoPago,
        nombreMetodoPago: paymentFormsText[values.metodoPago],
        receptor: {
          rfc: values.rfc,
          nombre: values.nombre,
          usoCFDI: values.usoCfdi,
          nombreUsoCFDI: usoCdifText[values.usoCfdi],
          calle: values.calle,
          numInterior: values.numeroInterior,
          numExterior: values.numero,
          colonia: values.colonia,
          localidad: values.localidad,
          municipio: values.municipio,
          estado: values.estado,
          observaciones: values.observaciones,
          pais: 'MÉXICO',
          cp: values.cp,
          regimenFiscal: values.regime,
          nombreRegimenFiscal: regimeOption ? regimeOption.label : '',
          domicilioFiscal: '',
          emails,
        },
      };
      const response = await client.post('/invoiceCFDI', formData, {
        'Content-Type': 'application/json',
      });
      const {data} = response;
      alert.success(data.message);
      resetForm();
      history.push('/');
    } catch (error) {
      alert.error(error.message);
      console.error(error);
    }
  };

  return (
    <div className="flex flex-wrap bg-white max-w-7xl mx-auto border border-gray-300 p-4 pt-0">
      <div className="w-full">
        <Formik
          initialValues={formInitialValues}
          validationSchema={validate}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            await createCdfi(values, resetForm);
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <div className="mt-5">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Datos de la Factura
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                    Información basica para generar la factura
                  </p>
                </div>
                <form onSubmit={handleSubmit} className="mt-10">
                  <div className="grid grid-cols-6 gap-6">
                    <Fields
                      {...{
                        values,
                        handleChange,
                        handleBlur,
                        client,
                        setFieldValue,
                      }}
                    />
                  </div>
                  <div className="mt-8 border-t border-gray-200 pt-5">
                    <div className="flex justify-end">
                      {/* <span className="inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                        >
                          Cancel
                        </button>
                      </span> */}
                      <span className="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          {!isSubmitting && 'Facturar'}
                          {isSubmitting && (
                            <Loading className="border-2 border-t-2 loader-div" />
                          )}
                        </button>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CdfiForm;
