import React, {useState, useEffect} from 'react';
import history from 'src/history';
import currencyFormatter from 'currency-formatter';
import pdfIcon from 'src/pages/images/pdf.svg';
import xmlIcon from 'src/pages/images/xml.svg';
import { Link } from 'react-router-dom';

const Complements = ({match, client, alert}) => {
  const [pagos, setPagos] = useState([]);
  const [execute, setExecute] = useState(false);
  let id = '';

  if (match.params) {
    id = match.params.id ? match.params.id : id;
  }

  if (id === '') {
    history.push('/');
  }

  useEffect(() => {
    async function fetchPagos() {
      try {
        if (!pagos.length && !execute) {
          const response = await client.get(`/payments/${id}`, {
            'Content-Type': 'application/json',
          });
          const {data} = response;
          const pagosFetch = data.data || [];
          setPagos(pagosFetch);
          setExecute(true);
        }
      } catch (error) {
        setExecute(true)
        alert.error(error.message);
      }
    }
    fetchPagos();
  }, [alert, client, pagos, id, execute]);

  return (
    <div className="h-screen flex overflow-hidden bg-white max-w-7xl mx-auto border border-gray-400">
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="border-b border-gray-400 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
            <div className="flex-1 min-w-0">
              <h1 className="text-2xl font-medium leading-6 text-gray-900">
                Pagos
              </h1>
            </div>
            <div className="mt-4 flex sm:mt-0 sm:ml-4">
              <span className="order-0 sm:order-1 sm:ml-3 shadow-sm rounded-md">
                <button
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: '/complemento',
                      state: {
                        ticket: id,
                      },
                    });
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 transition duration-150 ease-in-out"
                >
                  Nuevo
                </button>
              </span>
            </div>
          </div>
          <div className="hidden mt-8 sm:block">
            <div className="align-middle inline-block min-w-full border-b border-gray-400">
              <table className="min-w-full divide-y divide-gray-400">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Fecha de pago
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Forma de pago
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Monto
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Número de operación
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      ID Decumento
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Número de parcialidad
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Descargar
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-400">
                  {pagos.map((pago, index) => (
                    <tr
                      className={`${
                        index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                      }`}
                      key={index}
                    >
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                        {pago.fecha_pago}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 text-left">
                        {pago.forma_pago}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 text-right">
                        {currencyFormatter.format(pago.monto, {
                          code: 'USD',
                        })}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 text-left">
                        {pago.numero_operacion}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                        {pago.id_documento}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 text-left">
                        {pago.num_parcialidad}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                        <Link  download target="_blank" to={{pathname:`http://apifacturacion.papeleriasrosy.mx/storage/facturas/${pago.serie}${pago.folio}.pdf`}}>
                        <img src={pdfIcon} className="h-8 inline-block m-2" alt="pdf"></img>
                        </Link>
                        <Link  download target="_blank" to={{pathname:`http://apifacturacion.papeleriasrosy.mx/storage/facturas/${pago.serie}${pago.folio}.xml`}}>
                          <img src={xmlIcon} className="h-8 inline-block m-2" alt="xml"></img>
                        </Link>
                      </td>
                    </tr>
                  ))}
                  { !pagos && 
                    <tr>
                      <td
                        colSpan="7"
                        align="center"
                        className="py-4 text-xl uppercase"
                      >
                        No se encontraron Pagos relacionados con el ticket
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Complements;
