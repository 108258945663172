import React from 'react';
import {Route, Switch, Router} from 'react-router-dom';
import Api from 'src/api';
import AppContext from 'src/context/app-context';
import TicketForm from 'src/pages/Tickets/TicketForm';
import TicketDownload from 'src/pages/Tickets/TicketDownload';
import InvoiceComplementForm from 'src/pages/Invoice/InvoiceComplementForm';
import history from 'src/history';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import 'src/assets/main.css';
import './app.css';
import Complements from 'src/pages/Tables/Complements';
import CdfiForm from 'src/pages/Invoice/CdfiForm';
import {useAlert} from 'react-alert';

export const App = () => {
  const alert = useAlert();
  return (
    <React.Fragment>
      <AppContext.Provider
        value={{
          client: Api,
        }}
      >
        <div className="h-screen">
          {process.env.REACT_APP_MANTE === 'true' && (
            <div className={`bg-red-600 w-full h-8 text-center p-2`}>
              <div className="flex justify-center h-full items-center text-white">
                <FontAwesomeIcon
                  className="text-white"
                  icon={faExclamationCircle}
                />
                <p className="pl-4 text-white">
                  SITIO EN MANTENIMIENTO, FAVOR DE INTENTAR FACTURAR MAS TARDE
                </p>
              </div>
            </div>
          )}
          <Router history={history}>
            <Switch>
              <Route
                path="/"
                exact
                render={props => (
                  <TicketForm {...props} client={Api} alert={alert} />
                )}
              />
              <Route
                path="/complemento"
                exact
                render={props => (
                  <InvoiceComplementForm
                    {...props}
                    client={Api}
                    alert={alert}
                  />
                )}
              />
              <Route
                path="/pagos"
                exact
                render={props => (
                  <Complements {...props} client={Api} alert={alert} />
                )}
              />
              <Route
                path="/cdfi"
                exact
                render={props => (
                  <CdfiForm {...props} client={Api} alert={alert} />
                )}
              />
              <Route
                path="/download/:id"
                exact
                render={props => (
                  <TicketDownload {...props} client={Api} alert={alert} />
                )}
              />
              <Route
                path="/pagos/:id"
                exact
                render={props => (
                  <Complements {...props} client={Api} alert={alert} />
                )}
              />
              <Route
                path="*"
                exact
                render={props => (
                  <TicketForm {...props} client={Api} alert={alert} />
                )}
              />
            </Switch>
          </Router>
        </div>
      </AppContext.Provider>
    </React.Fragment>
  );
};

export default App;
