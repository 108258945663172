import React from 'react';
import './loading.css';

export const Loading = ({className}) => {
  return (
    <div className={`loader ease-linear rounded-full  ${className}`}></div>
  );
};

export default Loading;
