import React, {useEffect, useState} from 'react';
import {paymentMethods, usoCdif, paymentForms} from './options';
import {Field} from 'formik';
import {TextFieldRender, SelectFieldRender} from 'src/components/FormRenders';

export const Fields = ({
  values,
  handleChange,
  handleBlur,
  client,
  setFieldValue,
}) => {
  const [regimesOptions, setRegimesOptions] = useState([]);
  useEffect(() => {
    async function fetchSucursal() {
      try {
        if (!regimesOptions.length) {
          const response = await client.get('/taxRegime', {
            'Content-Type': 'application/json',
          });
          const {data} = response;
          const regimesFetch = data.data || [];
          const regimes = regimesFetch.map(regime => ({
            value: regime.key,
            label: regime.regime,
          }));
          setRegimesOptions(regimes);
          setFieldValue('regimeOptions', regimes);
        }
      } catch (error) {
        alert.error(error.message);
      }
    }
    fetchSucursal();
  }, [client, setFieldValue, regimesOptions]);

  const handleChangeRfc = async e => {
    try {
      const response = await client.get(`client/${e.currentTarget.value}`, {
        'Content-Type': 'application/json',
      });
      const {data} = response;
      if (data.data) {
        const userData = data.data;
        setFieldValue('nombre', userData.name);
        setFieldValue('calle', userData.street || '');
        setFieldValue('numero', userData.n_ext || '');
        setFieldValue('numeroInterior', '');
        setFieldValue('colonia', userData.neighborhood || '');
        setFieldValue('estado', userData.state || '');
        setFieldValue('localidad', userData.location || '');
        setFieldValue('municipio', userData.municipality || '');
        setFieldValue('cp', userData.cp);
        setFieldValue('email', userData.email);
        setFieldValue('regime', userData.tax_regime);
        setFieldValue('email2', '');
        setFieldValue('email3', '');
        setFieldValue('observaciones', '');
      } else {
        setFieldValue('nombre', '');
        setFieldValue('calle', '');
        setFieldValue('numero', '');
        setFieldValue('numeroInterior', '');
        setFieldValue('colonia', '');
        setFieldValue('estado', '');
        setFieldValue('localidad', '');
        setFieldValue('municipio', '');
        setFieldValue('cp', '');
        setFieldValue('regime', '');
        setFieldValue('email', '');
        setFieldValue('email2', '');
        setFieldValue('email3', '');
        setFieldValue('observaciones', '');
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Field
        label="RFC *"
        name="rfc"
        id="rfc"
        placeholder="RFC"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-6"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700 pl-1"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{
          autoFocus: true,
          onChange: handleChangeRfc,
          onKeyUp: e => {
            setFieldValue('rfc', e.target.value.toUpperCase());
          },
        }}
      />
      <Field
        label="Nombre *"
        name="nombre"
        id="nombre"
        placeholder="Nombre"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-6"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700 pl-1"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Calle (opcional)"
        name="calle"
        id="calle"
        placeholder="Calle"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-6"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700 pl-1"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Número Exterior (opcional)"
        name="numero"
        id="numero"
        placeholder="Número"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Número Interior (opcional)"
        name="numeroInterior"
        id="numeroInterior"
        placeholder="Número"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Colonia (opcional)"
        name="colonia"
        id="colonia"
        placeholder="Colonia"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Localidad (opcional)"
        name="localidad"
        id="localidad"
        placeholder="Localidad"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Municipio o delegación (opcional)"
        name="municipio"
        id="municipio"
        placeholder="Municipio o delegación"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Estado (opcional)"
        name="estado"
        id="estado"
        placeholder="Estado"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Código Postal *"
        name="cp"
        id="cp"
        placeholder="Código Postal"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Email *"
        name="email"
        id="email"
        placeholder="Email"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Segundo Email (opcional)"
        name="email2"
        id="email2"
        placeholder="Segundo Email"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Tercer Email (opcional)"
        name="email3"
        id="email3"
        placeholder="Tercer Email"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
      <Field
        label="Regimen Fiscal *"
        name="regime"
        id="regime"
        placeholder="Selecciona el regimen"
        component={SelectFieldRender}
        value={values.regime}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{
          options: regimesOptions,
        }}
      />
      <Field
        label="Método de pago *"
        name="metodoPago"
        id="metodoPago"
        placeholder="Método de pago"
        component={SelectFieldRender}
        value={values['paymentMethod']}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={true}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{
          options: paymentForms,
        }}
      />
      <Field
        label="Forma de pago *"
        name="formaPago"
        id="formaPago"
        placeholder="Forma de pago"
        component={SelectFieldRender}
        value={values['paymentMethod']}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={true}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{
          options: paymentMethods,
        }}
      />
      <Field
        label="Confirma forma de pago *"
        name="confirmFormaPago"
        id="confirmFormaPago"
        placeholder="Confirma forma de pago"
        component={SelectFieldRender}
        value={values['paymentMethod']}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={true}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{
          options: paymentMethods,
        }}
      />
      <Field
        label="Uso CFDI *"
        name="usoCfdi"
        id="usoCfdi"
        placeholder="Uso CDFI"
        component={SelectFieldRender}
        value={values['paymentMethod']}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={true}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{
          options: usoCdif,
        }}
      />
      <Field
        label="Observaciones (Opcional)"
        name="observaciones"
        id="observaciones"
        placeholder="Observaciones"
        component={TextFieldRender}
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameGrid="col-span-6 sm:col-span-3"
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        otherProps={{}}
      />
    </>
  );
};
