export const paymentMethods = [
  {
    label: '01 - Efectivo',
    value: '01',
  },
  {
    label: '02 - Cheque',
    value: '02',
  },
  {
    label: '03 - Transferencia',
    value: '03',
  },
  {
    label: '04 - Tarjeta de Crédito',
    value: '04',
  },
  {
    label: '28 - Tarjeta de Débito',
    value: '28',
  },
  {
    label: '99 - Por definir',
    value: '99',
  },
];

export const paymentMethodsText = {
  '01': 'Efectivo',
  '02': 'Cheque',
  '03': 'Transferencia',
  '04': 'Tarjeta de Crédito',
  '28': 'Tarjeta de Débito',
  '99': 'Por definir',
};

export const usoCdif = [
  {
    label: 'Adquisición de mercancias',
    value: 'G01',
  },
  {
    label: 'Gastos en general',
    value: 'G03',
  },
  {
    label: 'Pagos',
    value: 'CP01',
  },
];

export const usoCdifText = {
  G01: 'Adquisición de mercancias',
  G03: 'Gastos en general',
  CP01: 'Pagos',
};

export const paymentForms = [
  {
    label: 'Pago en Parcialidades o Diferido',
    value: 'PPD',
  },
  {
    label: 'Pago en Una sola Exhibición',
    value: 'PUE',
  },
];

export const paymentFormsText = {
  PPD: 'Pago en Parcialidades o Diferido',
  PUE: 'Pago en Una sola Exhibición',
};
