import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faMoneyCheckAlt} from '@fortawesome/free-solid-svg-icons';
const seletedTabStyle =
  'border-blue-500 font-medium text-sm leading-5 text-blue-600 focus:outline-none focus:text-blue-800 focus:border-blue-700';
const normalTabStyle =
  'border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300';

const TabsMenu = ({handleTab, openTab}) => {
  return (
    <div>
      <div className="sm:hidden">
        <select
          aria-label="Selected tab"
          className="form-select block w-full"
          onChange={val => handleTab(val.target.value)}
        >
          <option value={1}>My Account</option>
          <option value={2}>Company</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            <a
              onClick={e => {
                e.preventDefault();
                handleTab(1);
              }}
              href="#tab1"
              className={`w-1/2 py-4 px-1 text-center border-b-2  ${
                openTab === 1 ? seletedTabStyle : normalTabStyle
              }`}
            >
              <FontAwesomeIcon
                icon={faMoneyCheckAlt}
                className="text-base mr-2"
              />
              Pago
            </a>
            <a
              onClick={e => {
                e.preventDefault();
                handleTab(2);
              }}
              href="#tab2"
              className={`w-1/2 py-4 px-1 text-center border-b-2  ${
                openTab === 2 ? seletedTabStyle : normalTabStyle
              }`}
            >
              <FontAwesomeIcon icon={faFile} className="text-base mr-2" />
              Documentos
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TabsMenu;
