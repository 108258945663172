import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

const Alert = ({options, message, close}) => {
  let iconAlert = faInfoCircle;
  let bgcolor = 'bg-blue-500';
  let bordercolor = 'bg-blue-500';
  let title = 'Information';
  switch (options.type) {
    case 'success':
      iconAlert = faCheckCircle;
      bgcolor = 'bg-green-600';
      bordercolor = 'border-green-600';
      title = 'Success';
      break;
    case 'error':
      iconAlert = faExclamationCircle;
      bgcolor = 'bg-red-600';
      bordercolor = 'border-red-600';
      title = 'Error';
      break;
    default:
      iconAlert = faInfoCircle;
      bordercolor = 'bg-blue-500';
      bgcolor = 'border-blue-500';
      title = 'Information box';
  }
  return (
    <div className="shadow" role="alert">
      <div className="flex">
        <div className={`${bgcolor} w-16 text-center p-2`}>
          <div className="flex justify-center h-full items-center">
            <FontAwesomeIcon className="text-white" icon={iconAlert} />
          </div>
        </div>
        <div className={`bg-white w-full p-4`}>
          <div>
            <p className="text-gray-600 font-bold">{title}</p>
            <p className="text-gray-600 text-sm">{message}</p>
          </div>
        </div>
        <div
          className={`bg-white border-r-4 ${bordercolor} w-20 flex justify-center items-center`}
        >
          <button
            type="button"
            className="text-gray-600 text-xl z-4"
            onClick={close}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Alert;
