import React, {useState} from 'react';
import Select from 'react-select';
// import {ApolloConsumer} from 'react-apollo';
// import AsyncSelect from 'react-select/async';
import {DropzoneArea} from 'material-ui-dropzone';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

export const TextFieldRender = ({
  className,
  classNameGrid,
  classNameLabel,
  placeholder,
  label,
  id,
  name,
  type,
  field,
  form,
  otherProps = {},
}) => {
  const inputProps = _.omit(otherProps, ['hidden', 'showValue']);
  const handleOnChange = _e => {
    return false;
  };
  const customOnChange = otherProps.onChange || handleOnChange;
  return (
    <>
      {!otherProps.hidden && (
        <div className={classNameGrid}>
          <label className={classNameLabel} htmlFor={id}>
            {label}
          </label>
          <input
            {...field}
            className={className}
            id={id}
            type={type || 'text'}
            name={name}
            placeholder={placeholder}
            {...inputProps}
            onChange={e => {
              field.onChange(e);
              customOnChange(e);
            }}
          />
          {form.errors[field.name] && form.touched[field.name] && (
            <p className="mt-2 text-sm text-red-600 pl-2">
              {form.errors[field.name]}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export const TextAreaRender = ({
  className,
  classNameGrid,
  classNameLabel,
  placeholder,
  label,
  id,
  name,
  type,
  field,
  form,
  otherProps = {},
}) => {
  const inputProps = _.omit(otherProps, ['hidden', 'showValue']);
  return (
    <>
      {!otherProps.hidden && (
        <div className={classNameGrid}>
          <label className={classNameLabel} htmlFor={id}>
            {label}
          </label>
          <textarea
            {...field}
            className={className}
            id={id}
            type={type || 'text'}
            name={name}
            placeholder={placeholder}
            style={{resize: 'none'}}
            {...inputProps}
          />
          {form.errors[field.name] && form.touched[field.name] && (
            <p className="mt-2 text-sm text-red-600 pl-2">
              {form.errors[field.name]}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export const CheckboxRender = ({
  classNameGrid,
  classNameLabel,
  placeholder,
  label,
  id,
  name,
  field,
  form,
  otherProps = {},
}) => {
  const inputProps = _.omit(otherProps, ['hidden', 'showValue']);
  if (field.value) {
    inputProps.checked = true;
  }
  return (
    <>
      {!otherProps.hidden && (
        <div className={classNameGrid}>
          <label htmlFor={id} className={classNameLabel}>
            {label}
          </label>
          <input
            {...field}
            id={id}
            type="checkbox"
            className="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out h-9 w-9 mt-1"
            name={name}
            placeholder={placeholder}
            {...inputProps}
          />
          {form.errors[field.name] && form.touched[field.name] && (
            <p className="mt-2 text-sm text-red-600">
              {form.errors[field.name]}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export const SingleToggleRender = ({field, form}) => {
  const [isOn, setIsOn] = useState(field.value);

  const changeToggle = () => {
    form.setFieldValue(field.name, !isOn);
    setIsOn(!isOn);
  };

  return (
    <span
      role="checkbox"
      aria-checked={isOn}
      onClick={changeToggle}
      className={`${
        isOn ? 'bg-green-400' : 'bg-gray-200'
      } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
    >
      <span
        aria-hidden="true"
        className={`${
          isOn ? 'translate-x-5' : 'translate-x-0'
        } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
      ></span>
    </span>
  );
};

export const PasswordFieldRender = ({
  className,
  classNameGrid,
  classNameLabel,
  placeholder,
  label,
  id,
  name,
  field,
  form,
  otherProps = {},
}) => {
  const [show, setShow] = useState(false);
  const type = show ? 'text' : 'password';
  const inputProps = _.omit(otherProps, ['hidden', 'showValue']);
  return (
    <>
      {!otherProps.hidden && (
        <div className={classNameGrid}>
          <label className={classNameLabel} htmlFor={id}>
            {label}
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 right-0 flex items-center px-4 z-10">
              <button
                className="rounded text-2xl"
                onClick={() => {
                  setShow(!show);
                }}
                type="button"
              >
                {show && <FontAwesomeIcon icon={faEyeSlash} />}
                {!show && <FontAwesomeIcon icon={faEye} />}
              </button>
            </div>
            <input
              {...field}
              className={className}
              id={id}
              type={type}
              name={name}
              placeholder={placeholder}
              {...inputProps}
            />
          </div>
          {form.errors[field.name] && form.touched[field.name] && (
            <p className="text-red-500 text-sm mt-2 pl-2">
              {form.errors[field.name]}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export const SelectFieldRender = ({
  classNameGrid,
  classNameLabel,
  label,
  field,
  form,
  otherProps = {},
}) => {
  let defaultValue = !otherProps.multi ? {} : [],
    options = otherProps.options || [];
  defaultValue = otherProps.defaultValue || defaultValue;
  if (otherProps.enum && _.isEmpty(defaultValue)) {
    defaultValue = {value: field.value, label: field.value};
    if (otherProps.multi) {
      defaultValue = field.value.map(item => {
        return {value: item, label: item};
      });
    }
  } else if (field.value !== '' && !otherProps.multi) {
    defaultValue = options.find(option => option.value === field.value);
  } else if (_.isArray(field.value)) {
    defaultValue = field.value.map(item => {
      return {value: item.value, label: item.label};
    });
  }

  const [defaultValueAuto, setDefaultValueAuto] = useState(defaultValue);

  const handleOnChange = (
    option,
    setFieldValue,
    name,
    defaultValueAuto,
    setDefaultValueAuto,
    multi,
  ) => {
    if (option) {
      let value = option.value;
      let newVal = option;
      if (multi) {
        const valueOptions = option.map(val => val.value);
        value = valueOptions.filter((item, i, ar) => ar.indexOf(item) === i);
        newVal = _.uniqBy(option, 'value');
      }
      if (newVal !== defaultValueAuto) {
        setDefaultValueAuto(newVal);
      }
      setFieldValue(name, value);
    } else {
      if (multi) {
        setDefaultValueAuto([]);
        setFieldValue(name, []);
      } else {
        setDefaultValueAuto({});
        setFieldValue(name, '');
      }
    }
  };

  const HandleOnChange = otherProps.handleChange || handleOnChange;

  return (
    <div className={classNameGrid}>
      <label className={`${classNameLabel} mb-1`} htmlFor={field.id}>
        {label}
      </label>
      <Select
        name={field.name}
        id={field.id}
        classNamePrefix=""
        value={defaultValue}
        isDisabled={otherProps.disabled}
        isSearchable={true}
        options={options}
        onBlur={field.onBlur}
        isMulti={otherProps.multi}
        onChange={option => {
          HandleOnChange(
            option,
            form.setFieldValue,
            field.name,
            defaultValueAuto,
            setDefaultValueAuto,
            otherProps.multi,
            form.values,
          );
        }}
      />
      {form.errors[field.name] && (
        <p className="text-red-500 text-sm mt-2">{form.errors[field.name]}</p>
      )}
    </div>
  );
};

// export const AutocompleteFieldRender = ({
//   classNameGrid,
//   classNameLabel,
//   label,
//   field,
//   form,
//   otherProps = {},
// }) => {
//   let defaultValue = !otherProps.multi ? {} : [],
//     queries = otherProps.queries || {};
//   defaultValue = queries.defaultValue || defaultValue;
//   let showValue = otherProps.showValue;
//   const [defaultValueAuto, setDefaultValueAuto] = useState(defaultValue);
//   const [defaultValueData, setDefaultValueData] = useState(showValue);

//   const getExtraDataOption = (option) => {
//     let extraData = {};
//     if (queries.extraData) {
//       queries.extraData.forEach((data) => {
//         extraData[data.name] = option[data.field];
//       });
//     }
//     return extraData;
//   };

//   const handleOnChange = (
//     option,
//     setFieldValue,
//     name,
//     defaultValueAuto,
//     setDefaultValueAuto,
//     multi,
//     _client
//   ) => {
//     if (option) {
//       let value = option.value;
//       let newVal = option;
//       if (multi) {
//         const valueOptions = option.map((val) => val.value);
//         value = valueOptions.filter((item, i, ar) => ar.indexOf(item) === i);
//         newVal = _.uniqBy(option, "value");
//       }
//       if (newVal !== defaultValueAuto) {
//         setDefaultValueAuto(newVal);
//       }
//       setFieldValue(name, value);
//     } else {
//       if (multi) {
//         setDefaultValueAuto([]);
//         setFieldValue(name, []);
//       } else {
//         setDefaultValueAuto({});
//         setFieldValue(name, "");
//       }
//     }
//   };

//   const promiseOptions = (inputValue, _name, _setFieldValue, client) => {
//     return new Promise(async (resolve) => {
//       try {
//         if (!defaultValueData && inputValue !== "") {
//           let variables = { search: inputValue };
//           if (queries.variables) {
//             variables = {
//               ...variables,
//               ...queries.variables,
//             };
//           }
//           const { data } = await client.query({
//             query: queries.query,
//             variables,
//             fetchPolicy: "no-cache",
//           });
//           let optionsAutocomplete = data[queries.dataQuery];
//           let sudDataAutocomplete = data[queries.dataQuery];
//           if (queries.dataSubQuery) {
//             const subOptions = optionsAutocomplete[queries.dataSubQuery] || [];
//             optionsAutocomplete = subOptions;
//           }
//           const idValue = queries.optionId || "id";
//           const response = optionsAutocomplete.map((obj) => {
//             let extraData = getExtraDataOption(obj);
//             if (queries.dataSubQuery) {
//               const subData = sudDataAutocomplete || {};
//               extraData = getExtraDataOption(subData);
//             }
//             const label = queries.renderLabel
//               ? queries.renderLabel(obj)
//               : obj[queries.optionLabel];
//             let opt = {
//               value: obj[idValue],
//               label: label,
//               ...extraData,
//             };
//             return opt;
//           });
//           resolve(response);
//         } else if (defaultValueData && queries.queryFindOne) {
//           const { data } = await client.query({
//             query: queries.queryFindOne,
//             variables: { id: field.value },
//             fetchPolicy: "no-cache",
//           });
//           const option = data[queries.dataQueryFindOne];
//           const idValue = queries.optionId || "id";
//           const label = queries.renderLabel
//             ? queries.renderLabel(option)
//             : option[queries.optionLabel];
//           const newOptions = [
//             {
//               id: option[idValue],
//               label: label,
//             },
//           ];
//           setDefaultValueData(false);
//           setDefaultValueAuto(newOptions[0]);
//           resolve(newOptions);
//         } else {
//           setDefaultValueData(false);
//           resolve([]);
//         }
//       } catch (error) {
//         setDefaultValueData(false);
//         resolve([]);
//       }
//     });
//   };

//   const loadOptions = queries.loadOptions || promiseOptions;
//   const HandleOnChange = queries.handleChange || handleOnChange;

//   return (
//     <ApolloConsumer>
//       {(client) => {
//         return (
//           <div className={classNameGrid}>
//             <label className={`${classNameLabel} mb-1`} htmlFor={field.id}>
//               {label}
//             </label>
//             <AsyncSelect
//               cacheOptions
//               defaultOptions
//               loadOptions={(inputValue) =>
//                 loadOptions(inputValue, field.name, form.setFieldValue, client)
//               }
//               name={field.name}
//               id={field.id}
//               classNamePrefix=""
//               value={defaultValueAuto}
//               isDisabled={otherProps.disabled}
//               isSearchable={true}
//               onBlur={field.onBlur}
//               isMulti={otherProps.multi}
//               onChange={(option) => {
//                 HandleOnChange(
//                   option,
//                   form.setFieldValue,
//                   field.name,
//                   defaultValueAuto,
//                   setDefaultValueAuto,
//                   otherProps.multi,
//                   client
//                 );
//               }}
//             />
//             {form.errors[field.name] && (
//               <p className="text-red-500 text-sm mt-2">
//                 {form.errors[field.name]}
//               </p>
//             )}
//           </div>
//         );
//       }}
//     </ApolloConsumer>
//   );
// };

export const DropAreaZoneRender = ({
  field,
  form: {setFieldValue},
  label,
  classNameGrid,
  classNameLabel,
  otherProps = {
    filesLimit: 1,
    acceptedFiles: [],
    maxFileSize: 1000000,
    dropzoneText: '',
  },
}) => {
  const acceptedFiles = otherProps.acceptedFiles;
  const maxFileSize = otherProps.maxFileSize;
  const dropzoneText = otherProps.dropzoneText;
  const filesLimit = otherProps.filesLimit;
  const onChange = files => {
    setFieldValue(field.name, files);
    if (filesLimit === 1) {
      setFieldValue(field.name, files[0]);
    }
  };
  return (
    <div className={classNameGrid}>
      <label className={`${classNameLabel} mb-1`} htmlFor={field.id}>
        {label}
      </label>
      <DropzoneArea
        {...field}
        onChange={value => {
          onChange(value);
        }}
        acceptedFiles={acceptedFiles}
        maxFileSize={maxFileSize}
        filesLimit={filesLimit}
        style={{margin: 10}}
        dropzoneText={dropzoneText}
      />
    </div>
  );
};
