import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {positions, Provider as AlertProvider} from 'react-alert';
import App from './App';
import Alert from './components/Alert';

const options = {
  timeout: 5000,
  offset: '10px',
  position: positions.TOP_CENTER,
  containerStyle: {
    top: 10,
    pointerEvents: 'all',
  },
};

ReactDOM.render(
  <BrowserRouter>
    <AlertProvider template={Alert} {...options}>
      <App />
    </AlertProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
