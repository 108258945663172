import React from 'react';
import {Field} from 'formik';
import {TextFieldRender, SelectFieldRender} from 'src/components/FormRenders';
import currencyFormatter from 'currency-formatter';

const DocumentsForm = ({values, handleBlur, handleChange}) => {
  const currencies = [
    {
      label: 'MXN Pesos Mexicanos',
      value: 'MXN Pesos Mexicanos',
    },
  ];

  return (
    <div className="px-8 py-4 -mb-px flex">
      <div className="w-2/3 grid grid-cols-2 gap-4">
        <Field
          label="Serie:"
          name="serie"
          id="serie"
          placeholder="Serie"
          component={TextFieldRender}
          value={values['serie']}
          onChange={handleChange}
          onBlur={handleBlur}
          classNameLabel="block text-sm font-medium leading-5 text-gray-700"
          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          classNameGrid="col-span-2 sm:col-span-1"
          otherProps={{autoFocus: true, disabled: true}}
        />
        <Field
          label="Número de parcialidad:"
          name="parcial"
          id="parcial"
          placeholder="Número de parcialidad"
          component={TextFieldRender}
          value={values['parcial']}
          onChange={handleChange}
          onBlur={handleBlur}
          classNameLabel="block text-sm font-medium leading-5 text-gray-700"
          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          classNameGrid="col-span-2 sm:col-span-1"
          otherProps={{disabled: true}}
        />
        <Field
          label="Folio:"
          name="folio"
          id="folio"
          placeholder="Folio"
          component={TextFieldRender}
          value={values['folio']}
          onChange={handleChange}
          onBlur={handleBlur}
          classNameLabel="block text-sm font-medium leading-5 text-gray-700"
          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          classNameGrid="col-span-2 sm:col-span-1"
          otherProps={{disabled: true}}
        />
        <Field
          label="Importe Saldo Anterior:"
          name="oldTotal"
          id="oldTotal"
          placeholder="Importe anterior"
          component={TextFieldRender}
          value={values['oldTotal']}
          onChange={handleChange}
          onBlur={handleBlur}
          classNameLabel="block text-sm font-medium leading-5 text-gray-700"
          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          classNameGrid="col-span-2 sm:col-span-1"
          otherProps={{disabled: true, type: 'number'}}
        />
        <Field
          label="Método de pago:"
          name="paymentMethodDocumemnt"
          id="paymentMethodDocumemnt"
          placeholder="Método de pago"
          component={TextFieldRender}
          value={values['paymentMethodDocumemnt']}
          onChange={handleChange}
          onBlur={handleBlur}
          classNameLabel="block text-sm font-medium leading-5 text-gray-700"
          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          classNameGrid="col-span-2 sm:col-span-1 z-40"
          otherProps={{
            disabled: true,
          }}
        />
        <Field
          label="Importe Pagado:"
          name="totalPayment"
          id="totalPayment"
          placeholder="Importe Pagado"
          component={TextFieldRender}
          value={values['totalPayment']}
          onChange={handleChange}
          onBlur={handleBlur}
          classNameLabel="block text-sm font-medium leading-5 text-gray-700"
          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          classNameGrid="col-span-2 sm:col-span-1"
          otherProps={{disabled: true, type: 'number'}}
        />
        <Field
          label="Moneda: *"
          name="currency"
          id="currency"
          placeholder="Moneda"
          component={SelectFieldRender}
          value={values['currency']}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
          classNameLabel="block text-sm font-medium leading-5 text-gray-700"
          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          classNameGrid="col-span-2 sm:col-span-1 z-30"
          otherProps={{
            enum: true,
            disabled: true,
            options: currencies,
          }}
        />
        <Field
          label="Importe saldo insoluto:"
          name="difference"
          id="difference"
          placeholder="Importe saldo insoluto"
          component={TextFieldRender}
          value={values['difference']}
          onChange={handleChange}
          onBlur={handleBlur}
          classNameLabel="block text-sm font-medium leading-5 text-gray-700"
          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          classNameGrid="col-span-2 sm:col-span-1"
          otherProps={{disabled: true, type: 'number'}}
        />
      </div>
      <div className="w-1/3 px-8 py-4">
        <div className="mt-5 grid grid-cols-1 gap-2 sm:grid-cols-2">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate text-center">
                  Monto
                </dt>
                <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900 text-center">
                  {currencyFormatter.format(values['total'], {
                    code: 'USD',
                  })}
                </dd>
              </dl>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate text-center">
                  Monto Restante
                </dt>
                <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900 text-center">
                  {currencyFormatter.format(values['difference'], {
                    code: 'USD',
                  })}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsForm;
