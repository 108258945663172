import React from 'react';
import {Field} from 'formik';
import {TextFieldRender, SelectFieldRender} from 'src/components/FormRenders';
import _ from 'lodash';

const PaymentForm = ({values, handleBlur, handleChange, setFieldValue}) => {
  const currencies = [
    {
      label: 'MXN Pesos Mexicanos',
      value: 'MXN Pesos Mexicanos',
    },
  ];

  const paymentMethods = [
    {
      label: '01 - Efectivo',
      value: '01',
    },
    {
      label: '02 - Cheque',
      value: '02',
    },
    {
      label: '03 - Transferencia',
      value: '03',
    },
    {
      label: '04 - Tarjeta de Crédito',
      value: '04',
    },
    {
      label: '28 - Tarjeta de Débito',
      value: '28',
    },
    {
      label: '99 - Por definir',
      value: '99',
    },
  ];

  const handleChangeMonto = async e => {
    try {
      if (e.currentTarget.value) {
        let difference = values['oldTotal'],
          total = parseFloat(e.currentTarget.value);
        if (!_.isNaN(total)) {
          difference = difference - total;
        }
        setFieldValue('totalPayment', e.currentTarget.value);
        setFieldValue('difference', difference);
      } else {
        setFieldValue('totalPayment', 0);
        setFieldValue('difference', values['oldTotal']);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="grid grid-cols-4 gap-5 px-8 py-4">
      <Field
        label="Fecha de pago:"
        name="paymentDate"
        id="paymentDate"
        placeholder="Fecha de pago"
        component={TextFieldRender}
        value={values['paymentDate']}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        classNameGrid="col-span-4"
        otherProps={{}}
        type="date"
      />
      <Field
        label="Número de operación:"
        name="operation"
        id="operation"
        placeholder="Número de operación"
        component={TextFieldRender}
        value={values['operation']}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        classNameGrid="col-span-4"
        otherProps={{}}
      />
      <Field
        label="Moneda: *"
        name="currency"
        id="currency"
        placeholder="Moneda"
        component={SelectFieldRender}
        value={values['currency']}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={true}
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        classNameGrid="col-span-4 z-40"
        otherProps={{
          enum: true,
          disabled: true,
          options: currencies,
        }}
      />
      <Field
        label="Monto: *"
        name="total"
        id="total"
        placeholder="Monto"
        component={TextFieldRender}
        value={values['total']}
        onChange={handleChange}
        onBlur={handleBlur}
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        classNameGrid="col-span-4"
        otherProps={{
          type: 'number',
          onChange: handleChangeMonto,
        }}
      />
      <Field
        label="Forma de pago: *"
        name="paymentMethod"
        id="paymentMethod"
        placeholder="Forma de pago"
        component={SelectFieldRender}
        value={values['paymentMethod']}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={true}
        classNameLabel="block text-sm font-medium leading-5 text-gray-700"
        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        classNameGrid="col-span-4 z-40"
        otherProps={{
          enum: true,
          options: paymentMethods,
          defaultValue: {
            label: '01 - Efectivo',
            value: '01',
          },
        }}
      />
    </div>
  );
};

export default PaymentForm;
