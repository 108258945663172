import React from 'react';
import history from 'src/history';
import logo from 'src/pages/images/logo.png';
import pdfIcon from 'src/pages/images/pdf.svg';
import xmlIcon from 'src/pages/images/xml.svg';

const TicketDownload = ({match}) => {
  let id = '';

  if (match.params) {
    id = match.params.id ? match.params.id : id;
  }

  if (id === '') {
    history.push('/');
  }

  return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
      <div className="sm:mx-auto sm:w-full sm:max-w-md shadow sm:rounded-lg text-center p-12">
        <img
          className="mx-auto h-auto w-40"
          src={logo}
          alt="Papelería Rosy"
        />
        <h2 className="mt-6 text-center text-2xl leading-9 font-extrabold text-gray-900 uppercase">
          Ticket facturado
        </h2>
        <a href={`http://apifacturacion.papeleriasrosy.mx/storage/facturas/${id}.pdf`} download target="_blank" rel="noopener noreferrer">
          <img src={pdfIcon} className="h-24 inline-block my-12 mx-4" alt="pdf"></img>
        </a>
        <a href={`http://apifacturacion.papeleriasrosy.mx/storage/facturas/${id}.xml`} download target="_blank" rel="noopener noreferrer">
          <img src={xmlIcon} className="h-24 inline-block my-12 mx-4" alt="xml"></img>
        </a>
      </div>
    </div>
  );
};

export default TicketDownload;
